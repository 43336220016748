/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

/** @type {import('vue').ComputedRef<Record<string, string>>} */
export const ActionsNames = computed(() => ({
  ...ConditionsNames.value,
  ...WorkflowActionsNames.value,
}));

/** @type {import('vue').ComputedRef<Record<string, string>>} */
export const ConditionsNames = computed(() => ({
  if_email_opened: i18n.t('conditions.ifEmailOpened'),
  if_email_link_clicked: i18n.t('conditions.ifEmailLinkClicked'),
  if_message_link_clicked: i18n.t('conditions.ifMessageLinkClicked'),
  if_connected: i18n.t('conditions.ifConnected'),
  if_is_open: i18n.t('conditions.ifIsOpenProfile'),
  if_has_work_email: i18n.t('conditions.ifHasWorkEmail'),
  if_has_predicted_email: i18n.t('conditions.ifHasEnrichedEmail'),
  if_has_personal_email: i18n.t('conditions.ifHasPersonalEmail'),
  ab_testing: i18n.t('conditions.ABTesting'),
}));

/** @type {Record<string, string>} */
export const ConditionsIcons = Object.freeze({
  if_email_opened: 'bx-check-square',
  if_email_link_clicked: 'bx-link-alt',
  if_message_link_clicked: 'bx-pointer',
  if_connected: 'bx-git-branch',
  if_is_open: 'bx-check-circle',
  if_has_work_email: 'bx-briefcase',
  if_has_predicted_email: 'bx-at',
  if_has_personal_email: 'bx-home-alt',
  ab_testing: 'bx-shuffle',
});

/** @type {import('vue').ComputedRef<Record<string, string>>} */
export const WorkflowActionsNames = computed(() => ({
  li_connect: i18n.t('actions.sendConnectionRequest'),
  li_connect_by_email: i18n.t('actions.sendConnectionByEmail'),
  li_connect_profile: i18n.t('actions.sendConnectionRequest'),
  li_send_message: i18n.t('actions.sendMessage'),
  li_send_inmail: i18n.t('actions.sendInMail'),
  li_actions: i18n.t('actions.performAction'),
  li_invite_event: i18n.t('actions.inviteEvents'),
  send_email: i18n.t('actions.sendEmail'),
  send_webhook: i18n.t('actions.sendWebhook'),
  send_sms: i18n.t('actions.sendSms'),
  manual_call: i18n.t('actions.makeCall'),
  voicemail_drop: i18n.tc('common.voicemailDrop'),
  enrich_profile: i18n.t('actions.enrichProfile'),
  push_to_crm: i18n.t('actions.pushToCrm'),
  manual_task: i18n.tc('common.manualTask'),
  reveal_personality: i18n.t('actions.revealPersonality'),
}));

/** @type {Record<string, string>} */
export const WorkflowActionsTypesEnum = Object.freeze({
  LI_CONNECT: 'li_connect',
  LI_CONNECT_BY_EMAIL: 'li_connect_by_email',
  LI_CONNECT_PROFILE: 'li_connect_profile',
  LI_SEND_MESSAGE: 'li_send_message',
  LI_SEND_INMAIL: 'li_send_inmail',
  LI_ACTIONS: 'li_actions',
  LI_INVITE_EVENT: 'li_invite_event',
  SEND_EMAIL: 'send_email',
  SEND_WEBHOOK: 'send_webhook',
  SEND_SMS: 'send_sms',
  MANUAL_CALL: 'manual_call',
  VOICEMAIL_DROP: 'voicemail_drop',
  ENRICH_PROFILE: 'enrich_profile',
  PUSH_TO_CRM: 'push_to_crm',
  MANUAL_TASK: 'manual_task',
  REVEAL_PERSONALITY: 'reveal_personality',
});

/** @type {Record<string, string>} */
export const WorkflowActionsIcons = Object.freeze({
  li_connect: 'bx-user-plus',
  li_connect_profile: 'bx-user-plus',
  li_connect_by_email: '',
  li_send_message: 'bx-message-alt-detail',
  li_send_inmail: 'bx-edit',
  li_actions: 'bxs-user-detail',
  li_invite_event: 'bx-calendar-event',
  send_email: 'bx-mail-send',
  send_webhook: 'bx-shape-triangle',
  send_sms: 'bx-message-square-dots',
  manual_call: 'bx-phone',
  voicemail_drop: 'bx-envelope',
  send_whatsapp_message: 'bxl-whatsapp',
  enrich_profile: 'bx-diamond',
  push_to_crm: 'bx-line-chart',
  manual_task: 'bx-task',
  reveal_personality: 'bx-badge-check',
});
