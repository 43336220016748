/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
/**
 * Get timezone offset in minutes
 * @returns timezone offset in minutes, e.g. `+3 -> +180, -6 -> -360`
 */
export const getTimezoneOffset = () => {
    return new Date().getTimezoneOffset() * -1;
};
/**
 * Generate months periods
 * @param months Number of months periods
 * @param direction Forward = `1` / Backward = `-1`
 * @returns Array of months periods
 */
export const getMonthsPeriods = (months, direction = 1) => {
    const periods = [];
    // Protection for unexpected direction value
    const _direction = direction >= 0 ? 1 : -1;
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    for (let i = 0; i < months; i++) {
        periods.push({
            start: getIsoDate(year, month + (i * _direction), 1),
            end: getIsoDate(year, month + 1 + (i * _direction), 0),
        });
    }
    return periods;
};
/**
 * Get ISO date string
 * @param year target year
 * @param month month index (starts from 0)
 * @param day day number (1...31)
 * @returns date string, e.g. 2023-07-19
 */
export const getIsoDate = (year, month, day) => {
    const offset = new Date().getTimezoneOffset() / 60 * -1;
    const isoString = new Date(year, month, day, offset).toISOString();
    return isoString.slice(0, 10);
};
